import React, { Suspense, lazy, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, redirect, Navigate, useNavigate} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
const AdminRoutes = lazy(() => import('./routes/AdminRoutes'));
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
   <Suspense fallback={null}>
    <React.StrictMode>
      <BrowserRouter basename='/'>
        <AdminRoutes />
      </BrowserRouter>
      <ToastContainer/>
    </React.StrictMode>
   </Suspense>
);

reportWebVitals();
